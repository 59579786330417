export const ETCTheme = Object.freeze({
  token: {
    // Global changes
    fontFamily: "var(--fontReg)",
    fontSize: "14px",
    colorPrimary: "#ed1a3b",
    colorPrimaryHover: "var(--redDark)",
    colorSuccessHover: "var(--greenDark)",
    colorSuccess: "var(--green)",
    colorWarning: "var(--gold)",
    colorWarningActive: "var(--goldDark)",
    colorError: "var(--burgandy)",
    colorErrorBgHover: "var(--burgandyDark)",
    colorInfo: "var(--ocean)",
    colorTextBase: "#333333",
    borderRadius: 0,
    boxShadow: "none",
    boxShadowSecondary: "none",
    wireframe: false
  },
  components: {
    Button: {
      lineHeight: 1.7,
    },
    Table: {
      colorBgContainer: "#ffffff",
      colorFillAlter: "#cfcfcf",
      colorBorderSecondary: "#333333",
      controlItemBgHover: "#f5f5f5",
    }
  }
});
