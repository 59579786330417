import React from 'react'
import { Tooltip } from 'antd';
import './BDOTooltip.css';

const BDOTooltip = (props) => {
  const { title, children, alignRight } = props;
  const classList = ['bdo-tooltip'];

  if (alignRight) classList.push('align-right');
  if (!alignRight) classList.push('left-margin');
  
  return (
    <Tooltip title={title} className={classList.join(" ")}  placement='topLeft' trigger='hover' {...props}>
      {children}
    </Tooltip>
  )
}

export default BDOTooltip