import React from "react";
import { Route, Routes} from "react-router-dom";
import { Home } from "../landing/Home";
import Loading from "../misc/Loading";

const EngagementDetail = React.lazy(() => import("../detail/EngagementDetail"));
const Create = React.lazy(() => import("../detail/Create"));

export default function AppRoutes() {
  return (
    <>      
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route 
          path="/myengagements/:action/:uuid/:entityname/:yearend/:eyid" 
          element={
            <React.Suspense fallback={<Loading />}>
              <EngagementDetail />
            </React.Suspense>
          } 
        />
        <Route 
          path="/myengagements/:uuid/:entityname" 
          element={
            <React.Suspense fallback={<Loading />}>
              <Create />
            </React.Suspense>
          } 
        />   
        <Route path="/myengagements/" element={<Home />} />     
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}
