import React from "react";
import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./BDOModal.css";

const BDOModal = (props) => {
  const { title, open, onCancel, onOk, children } = props;
  return (
    <Modal
      className="bdoModal"
      centered
      title={title}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      closeIcon={
        <CloseCircleOutlined
          className="closeIcon"
          style={{ fontSize: "var(--L)", color: "var(--charcoal)" }}
        />
      }
      {...props}
    >
      <div className="modalContent">{children}</div>
    </Modal>
  );
};

export default BDOModal;
