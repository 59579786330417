import React from "react";
import { Button } from "antd";
import "./BDOButton.css";

const BDOButton = (props) => {
  const { children } = props;
  return (
    <div className="bdoButtonContainer">
      <Button {...props}>
        {children}
      </Button>
    </div>
  );
};

export default BDOButton;
