import React, { useEffect, useState } from "react";
import { AutoComplete, Input, Spin } from "antd";
import { useMsal } from "@azure/msal-react";
import {
  SearchOutlined,
  DownOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "./NewEngagementModal.css";
import { BDOModal, BDOButton } from '../../common/index';
import { useBDOGraph } from "../../../hooks/useBDOGraph";

const NewEngagementModal = (props) => {
  const { isModalOpen, onAddButtonClick, onCancel, onAddInModalClick } = props;
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [projectMenuOpen, setProjectMenuOpen] = useState(false);

  const { GetAssignedOrganisations } = useBDOGraph();

  // Get the logged user account from MSAL
  const { accounts } = useMsal();
  const account = accounts[0];

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetAssignedOrganisations(account.username);
      if (response && response.status === 200) {
        setOptions(
          response.data.map((x) => ({
            label: x.name,
            value: x.name,
            key: x.uuid,
          }))
        );
      } else {
        //TODO
        // logging.error("Failed to fetch AssignedOrganisations, non success status code", { response: response });
        // setHasError(true);
      }
      setProjectsLoading(false);
    };

    if (isModalOpen) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const showModal = () => {
    onAddButtonClick();
  };

  const handleAddButtonClick = () => {
    onAddInModalClick(selectedOption); // Call the function to show the Detail component
    setProjectMenuOpen(false);
  };

  const handleChange = (option) => {
    var selectedOption = options.find((o) => o.value === option);
    setSelectedOption(selectedOption);
  };

  const handleClose = () => {
    setProjectMenuOpen(false);
    setSelectedOption([]);
    setOptions([]);
    onCancel();
  };

  return (
    <div>
      <BDOButton type="primary" shape="square" onClick={showModal}>
        New Engagement {<PlusOutlined style={{ fontSize: "var(--L)" }} />}
      </BDOButton>

      <BDOModal
        title="Add new engagement"
        open={isModalOpen}
        onCancel={handleClose}
        onOk={handleAddButtonClick}
        destroyOnClose={true}
        okText="Add"
        okButtonProps={{ disabled: selectedOption.length === 0 }}
      >
        <p className="fieldLabel">Type to search for entity or project name</p>

        <AutoComplete
          onSelect={handleChange}
          options={options}
          filterOption={(inputValue, option) => {
            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
          }}
          className="searchEngagement"
          popupClassName="searchEngagementPopup"
          disabled={projectsLoading}
          value={selectedOption.value}
          open={projectMenuOpen}
          onClick={() => setProjectMenuOpen(!projectMenuOpen)}
          onBlur={() => setProjectMenuOpen(false)}
        >
          <Input
            className="searchEngagementInput"
            addonBefore={<SearchOutlined />}
            addonAfter={
              projectsLoading ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <DownOutlined style={{ color: "var(--red)" }} onClick={() => setProjectMenuOpen(!projectMenuOpen)} />
              )
            }
            
          />
        </AutoComplete>
        <p className="new-engagement-hint">
          You will only see entities you have access to, if it's not listed
          here, please check your access in CMS.
        </p>
      </BDOModal>
    </div>
  );
};

export default NewEngagementModal;
