
import { PageLayout } from "../layout/PageLayout/PageLayout.jsx";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Errors.css";

export const ErrorPage = (props) => {
  const { state } = props;

  return (
    <PageLayout>
      <div className="errorPageContainer">
        <div className="iconContainer">

        </div>

        <h1>
          Oops! Something's not right...
        </h1>

        <p className="infoText">
          please contact{" "}
          <a
            href="mailto:it.servicedesk@bdo.co.uk"
          >
            it.servicedesk@bdo.co.uk
          </a>
        </p>

        <Accordion>
          <Accordion.Item className="accordionItem" eventKey="0">
            <Accordion.Header>Technical details</Accordion.Header>
            <Accordion.Body className="accordionBody">
              {state.message}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </PageLayout>
  );
};
