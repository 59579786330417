import React from "react";
import { Alert } from "antd";
import './BDOAlert.css';

const BDOAlert = (props) => {
  const { message } = props;
  return (
    message && (
      <div className={`bdo-alert-container ${message.type}`}>
        <Alert
          message={message.title}
          description={message.description}
          type={message.type}
          showIcon
          closable
        />
      </div>
    )
  );
};

export default BDOAlert;
