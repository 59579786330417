import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div>
        <div className="text-container">
          Please note that all timestamps are set in time zone GMT or BST.
        </div>
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600.96 210.23" fill="#333">
          <g id="product-text">
            <path d="M17.37,.2h6.54l18.05,45.09h-8.85l-4.36-11.24H12.74l-4.22,11.24H0L17.37,.2Zm8.86,27.18l-3.13-8.92c-.95-2.68-1.7-5-2.25-6.95h-.14c-.32,1.18-1.09,3.52-2.32,7.02l-3.27,8.86h11.1Z" />
            <path d="M64.03,.41h10.83c4.13,0,7.49,.64,10.08,1.91,4.86,2.41,7.29,6.67,7.29,12.81,0,2.86-.69,5.46-2.08,7.8-1.38,2.34-3.55,4.12-6.5,5.35-2.68,1.04-5.86,1.57-9.54,1.57h-1.91v15.46h-8.17V.41Zm10.63,22.75c2.18,0,3.93-.34,5.25-1.02,2.5-1.23,3.75-3.56,3.75-7.02,0-3.18-1.18-5.47-3.54-6.88-1.32-.77-3.18-1.16-5.59-1.16h-2.32V23.16h2.45Z" />
            <path d="M101.84,.41h12.26c3.86,0,7.06,.61,9.6,1.84,4.45,2.09,6.68,5.74,6.68,10.97,0,2.91-.79,5.35-2.38,7.32-1.59,1.98-3.72,3.39-6.4,4.26v.14c1.32,.73,3.18,3.16,5.59,7.29l7.49,13.08h-9.4l-6.47-11.38c-2.04-3.77-3.68-5.81-4.9-6.13h-3.88v17.51h-8.17V.41Zm11.85,20.71c1.91,0,3.56-.39,4.97-1.16,2.09-1.18,3.13-3.16,3.13-5.93,0-2.59-1-4.47-3-5.65-1.27-.86-2.95-1.29-5.04-1.29h-3.75v14.03h3.68Z" />
            <path d="M145.85,39.64c-4.22-4.22-6.33-9.76-6.33-16.62s2.1-12.35,6.3-16.62c4.2-4.27,9.64-6.4,16.31-6.4s12.06,2.15,16.28,6.44c4.22,4.29,6.33,9.82,6.33,16.59s-2.12,12.34-6.37,16.59c-4.25,4.25-9.66,6.37-16.25,6.37s-12.06-2.11-16.28-6.33Zm26.36-4.84c2.54-2.86,3.81-6.79,3.81-11.78s-1.25-8.88-3.75-11.78c-2.5-2.91-5.88-4.36-10.15-4.36s-7.59,1.43-10.12,4.29c-2.52,2.86-3.78,6.81-3.78,11.85s1.26,8.92,3.78,11.78c2.52,2.86,5.89,4.29,10.12,4.29s7.54-1.43,10.08-4.29Z" />
            <path d="M194.42,.41h13.96c7.81,0,13.85,2,18.12,5.99s6.4,9.38,6.4,16.14-2.27,12.36-6.81,16.52c-4.54,4.15-10.69,6.23-18.46,6.23h-13.21V.41Zm13.35,37.94c5.59,0,9.71-1.46,12.36-4.39,2.66-2.93,3.98-6.69,3.98-11.27,0-4.81-1.41-8.57-4.22-11.27-2.82-2.7-6.9-4.05-12.26-4.05h-5.04v30.99h5.18Z" />
            <path d="M243.88,37.26c-1.14-2.27-1.7-5.18-1.7-8.72V.41h8.17V28c0,2.41,.41,4.43,1.23,6.06,1.63,3.36,4.7,5.04,9.2,5.04s7.56-1.68,9.2-5.04c.82-1.63,1.23-3.68,1.23-6.13V.41h8.17V28.54c0,3.59-.57,6.52-1.7,8.79-2.77,5.77-8.4,8.65-16.89,8.65s-14.17-2.91-16.89-8.72Z" />
            <path d="M325.62,42.44c-4.31,2.36-9.06,3.54-14.24,3.54-7.17,0-12.75-2.08-16.72-6.23-3.97-4.16-5.96-9.62-5.96-16.38s2.18-12.18,6.54-16.65c4.36-4.47,10.08-6.71,17.17-6.71,4.54,0,8.47,.73,11.78,2.18v7.63c-3.68-1.95-7.54-2.93-11.58-2.93-4.77,0-8.49,1.56-11.17,4.67-2.68,3.11-4.02,6.98-4.02,11.61s1.35,8.62,4.05,11.55c2.7,2.93,6.32,4.39,10.86,4.39,3.72,0,7.4-1.04,11.04-3.13l2.25,6.47Z" />
            <path d="M342.58,7.36h-13.08V.41h35.49l-2.25,6.95h-11.99V45.3h-8.17V7.36Z" />
            <path d="M391.02,39.64c-4.22-4.22-6.33-9.76-6.33-16.62s2.1-12.35,6.3-16.62,9.64-6.4,16.31-6.4,12.06,2.15,16.28,6.44c4.22,4.29,6.33,9.82,6.33,16.59s-2.12,12.34-6.37,16.59c-4.25,4.25-9.66,6.37-16.25,6.37s-12.06-2.11-16.28-6.33Zm26.36-4.84c2.54-2.86,3.81-6.79,3.81-11.78s-1.25-8.88-3.75-11.78c-2.5-2.91-5.88-4.36-10.15-4.36s-7.59,1.43-10.12,4.29c-2.52,2.86-3.78,6.81-3.78,11.85s1.26,8.92,3.78,11.78,5.89,4.29,10.12,4.29,7.54-1.43,10.08-4.29Z" />
            <path d="M439.59,.41h25l-2.25,6.95h-14.58v11.72h14.3v6.95h-14.3v19.28h-8.17V.41Z" />
          </g>
          <g id="logo-labs">
            <path d="M507.5,210.18c-37.5-.01-67.88-30.26-67.87-67.56,.01-37.3,30.42-67.53,67.91-67.51,1.53,0,3.06,.05,4.59,.16l15.4-21.89-7.39-5.14,8.46-12,19.45,13.56-28.9,41.04-4.48-.58c-29.1-3.91-55.87,16.38-59.8,45.33-3.93,28.95,16.47,55.58,45.57,59.49,29.1,3.91,55.87-16.38,59.8-45.33,1.48-10.88-.48-21.94-5.61-31.66l-2.04-3.92,28.9-41.06,19.45,13.56-8.46,11.91-7.39-5.17-15.4,21.89c15.14,34.12-.39,74-34.69,89.06-8.67,3.81-18.04,5.77-27.52,5.75v.07Z" />
            <ellipse cx="521.72" cy="111.85" rx="11.15" ry="11.09" className="logo-labs-ellipse" />
            <ellipse cx="542.06" cy="93.63" rx="7.16" ry="7.13" className="logo-labs-ellipse" />
            <path d="M469.27,131c-6.5,21.05,5.38,43.37,26.55,49.84,21.16,6.47,43.59-5.35,50.1-26.41,2.36-7.63,2.36-15.79,0-23.43h-76.64Z" className="logo-labs-path" />
            <path d="M.01,76.66H30.75v105.91h45.37v25.76H.01V76.66Zm137.01-.76h25.32l54.8,132.27h-32.9l-12.65-31.38h-43l-12.04,31.38h-31.47l51.95-132.27Zm25.32,76.53l-6.22-18.24c-3.02-8.91-4.61-14.59-5.62-18.79h-.4c-1.65,6.46-3.74,12.81-6.25,18.99l-6.72,17.99,25.2,.04Zm64.82-75.93h44.39c28.72,0,41.57,16.77,41.57,33.4,.08,10.79-5.67,20.79-15.06,26.19v.33c14.58,3.49,24.71,16.66,24.29,31.58,0,19.4-15.67,40.08-47.41,40.08h-47.77V76.51Zm39.96,52.75c10.65,0,16.07-6.41,16.07-13.61,0-7.79-5.42-14.18-16.88-14.18h-8.42v27.79h9.22Zm3.42,54.13c14.66,0,21.09-4.99,21.09-15.59,0-8.77-6.72-13.78-18.27-13.78h-15.47v29.37h12.65Zm65.59-8.57c8.9,6.37,19.55,9.85,30.51,9.98,8.95,0,18.89-3.01,18.89-11.58,0-21.78-56.23-18.99-56.23-59.54,0-20.2,16.25-38.57,45.57-38.57,12.05-.25,24,2.21,34.96,7.19v27.41c-12.24-5.99-23.1-9.2-33.96-9.2-7.43,0-13.86,2.61-13.86,10.6,0,20.37,56.25,17.19,56.25,59.54,0,24.38-22.09,39.57-50.43,39.57-15.67,0-31.92-4.21-43.38-12.4l11.66-23Z" />
          </g>
        </svg>
        </div>

      </div>
    </footer>
  );
};
export default Footer;
