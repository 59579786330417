export const appRoutes = {
  home: {
    list: "/home"
  },
  engagement: {
    create: "/myengagements/:uuid/:entityname",    
    manage: "/myengagements/:action/:uuid/:entityname/:yearend/:eyid",
    teamMember: "/myengagements/:uuid/:entityname/:yearend/:eyid",
  }
};

export function buildAppRoute(route, tokens, backUrl = false) {
  let url = `${route}`;

  if (backUrl) {
    url = `${url}?backUrl=${window.location.pathname}`;
  }

  if (tokens && typeof tokens === "object") {
    for (const [key, value] of Object.entries(tokens)) {
      url = url.replace(new RegExp(`:${key}`, "gi"), value);
    }
  }

  return url;
}
