export function useTableFilter(dataSource) {

  function handleTableFilter(dataIndex) {
    let filteredItems = [];

    if (dataSource.length > 0) {
      if (dataIndex === "yearEnd") {
        // exception for yearEnd ensure date is formatted correctly
        filteredItems = dataSource.map((item) => {
          const formattedDate = new Date(item[dataIndex]).toLocaleDateString(
            "en-GB",
            {
              day: "numeric",
              month: "short",
              year: "numeric",
            }
          );
          return {
            text: formattedDate,
            value: item[dataIndex],
          };
        });
      } else {
        // all other columns
        filteredItems = dataSource.map((item) => {
          return {
            text: item[dataIndex], // todo:capitalize first letter
            value: item[dataIndex],
          };
        });
      }
    }

    // remove any duplicate values
    const filter = filteredItems.filter(
      (item, index, self) =>
        {
          return self.findIndex((t) => t.value === item.value) === index
        }
    );
    
    return filter;
  }

  return { handleTableFilter };
}
