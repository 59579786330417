export const apiBaseUrl = `https://${process.env.REACT_APP_ENGAGEMENTS_BASE}/api`;

export const apiRoutes = {
  Engagement:{
        getAllByUser: "/Engagement/List",
        getByEngagementYearId: "/Engagement/GetByEngagementYearId/:eyid",
        exportEngagementtoPdf: "/EngagementYears/:eyid/ExportToPdf",
        add: "/Engagement/AddNew",
        update: "/Engagement/Update"
  },
  BDOGraph : {
      getAssignedOrganisaitons: "/BDOGraph/RelatedOrganisations",
  },
  MasterData :{
    getAuditTypes: "/AuditTypes"
  },
  TeamMembers:{
    getAll: "/TeamMember/List/:eyid",
    getUsers: "/MSGraph/GetUsers/?startWith=:startsWith",
    Add: "/TeamMember/to/EngagementYear/add",
    getRoles: "/Roles",
    inviteExternal:"/MSGraph/InviteUser",
    bulkInvite:"/TeamMember/request/send/all",
    sendRequest:"/TeamMember/request/send",
    resendRequest:"/TeamMember/request/resend",
    getRoleForUser:"/Users/Engagement/Role",
  }
};

export function buildApiRoute(route, tokens) {
  let url = `${apiBaseUrl}${route}`;

  if (tokens && typeof tokens === "object") {
    for (const [key, value] of Object.entries(tokens)) {
      url = url.replace(new RegExp(`:${key}`, "gi"), value);
    }
  }

  return url;
}
