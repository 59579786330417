import React from "react";
import { Table } from "antd";
import "./BDOTable.css";

const BDOTable = (props) => {
  return (
    <Table
      pagination={{
        position: ["bottomCenter"],
        showSizeChanger: true,
        defaultPageSize: 5,
        pageSizeOptions: [5, 10, 20, 50],
      }}
      {...props}
    />
  );
};

export default BDOTable;
