import { apiRoutes, buildApiRoute } from "../config/apiRoutes";
import { useEngagementTeamConfirmationsApi } from "./useEngagementTeamConfirmationsApi";

export function useBDOGraph() {
  const { apiPost } = useEngagementTeamConfirmationsApi();

  async function GetAssignedOrganisations(email) {
    return await apiPost(buildApiRoute(apiRoutes.BDOGraph.getAssignedOrganisaitons), { "email":email });
  }


  return { GetAssignedOrganisations };
}
