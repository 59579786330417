import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType, InteractionStatus } from "@azure/msal-browser";
import Error from "../errors/Error";
import Loading from "../misc/Loading";
import React from "react";
import { scopesRequest } from "../../config/authConfig";

export default function AppAuth(props) {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={scopesRequest}
      errorComponent={Error}
      loadingComponent={Loading}
    >
      {(isAuthenticated && (inProgress === InteractionStatus.None)) && <>{props.children}</>}
    </MsalAuthenticationTemplate>
  );
}
