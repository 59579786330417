import { apiRoutes, buildApiRoute } from "../config/apiRoutes";
import { useEngagementTeamConfirmationsApi } from "./useEngagementTeamConfirmationsApi";

export function useEngegament() {
  const { apiGet, apiPost } = useEngagementTeamConfirmationsApi();

  async function ListEngagementsByUser(email) {
    return await apiPost(buildApiRoute(apiRoutes.Engagement.getAllByUser), { "email":email });
  }

  async function GetEngagement(engagementYearId) {
    return await apiGet(buildApiRoute(apiRoutes.Engagement.getByEngagementYearId, { eyid: engagementYearId}));
  }

  async function ExportEngagementToPdf(engagementYearId) {
    return await apiPost(buildApiRoute(apiRoutes.Engagement.exportEngagementtoPdf, { eyid: engagementYearId}), null, null, 'application/json', 'blob');
  }

  return { ListEngagementsByUser, GetEngagement, ExportEngagementToPdf };
}
