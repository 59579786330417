import React from "react";
import "./styles/App.css";
import { PageLayout } from "./components/layout/PageLayout/PageLayout.jsx";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsightsReactPlugin } from "./components/insights/AppInsights";
import { UnhandledException } from "./components/errors/UnhandledException";
import AppAuth from "./components/auth/AppAuth";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";
import ErrorBoundaryInsights from "./components/errors/ErrorBoundaryInsights";
import { BrowserRouter} from "react-router-dom";
import AppRoutes from "./components/routing/AppRoutes";


function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <AppInsightsContext.Provider value={appInsightsReactPlugin}>
      <ErrorBoundaryInsights onError={() => <UnhandledException />} appInsights={appInsightsReactPlugin}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>         
            <AppAuth>
              <div className="App">
                <PageLayout>
                  <main role="main">
                    <AppRoutes/>
                  </main>
                </PageLayout>
              </div>
            </AppAuth>
            </MsalProvider>
          </BrowserRouter>        
      </ErrorBoundaryInsights>
    </AppInsightsContext.Provider>
  );
}
export default App;
